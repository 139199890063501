<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="ProfileIcon" size="19" />
      <h4 class="mb-0">Additional Information</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-form>
        <!-- Form: Add Inf -->
        <b-row>
          <!-- Field: Clearance -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Clearance" label-for="profile-clearance">
              <v-select
                id="profile-clearance"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="attributes.clearance"
                :options="clearanceOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (attributes.clearance = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Relocatable -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Relocatable" label-for="profile-relocatable">
              <v-select
                id="profile-relocatable"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="attributes.relocatable"
                :options="relocatableOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (attributes.relocatable = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Relocatable -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Remote" label-for="profile-remote">
              <v-select
                id="profile-remote"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="attributes.remote"
                :options="remoteOptions"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (attributes.remote = val)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useProfilesList from '../../profile-list/useProfileList';
import placementStoreModule from '../../placementStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    profileData: function () {},
  },
  data: () => {
    return {
      submit: {
        sync: false,
      },
      attributes: {
        relocatable: 'no',
        remote: 'no',
        clearance: 'none',
        veteran: true,
      },
    };
  },
  methods: {
    doSaveChanges() {
      const pwd = this.profileData;
      const id = this.profileData.id;

      const pl = { attributes: this.attributes };

      store
        .dispatch('app-placement/updateProfile', { id, profileData: pl })
        .then((response) => {
          this.attributes = response.data.attributes;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.loadProfile();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    loadProfile() {
      store.dispatch('app-placement/fetchProfile', { id: this.profileDataInfo.id }).then((response) => {
        if (this.profileData.attributes) {
          this.attributes = this.profileData.attributes;
        }
      });
    },
  },
  mounted() {
    this.loadProfile();
  },
  setup(props) {
    const profileDataInfo = ref(props.profileData);

    const PROFILE_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, placementStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
    });

    const { statusOptions, clearanceOptions, relocatableOptions, remoteOptions, fundingSourceOptions } = useProfilesList();

    return {
      profileDataInfo,
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
